var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    _vm._b(
      {
        attrs: { layout: "vertical", form: _vm.form },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          }
        }
      },
      "a-form",
      _vm.formItemLayout,
      false
    ),
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 12, xs: 24, s: 24, md: 24, lg: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.assetNumber.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.assetNumber.decorator,
                        expression: "formRules.assetNumber.decorator"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      name: _vm.formRules.assetNumber.name,
                      placeholder: _vm.$t(_vm.formRules.assetNumber.placeholder)
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.book.label) }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.book.decorator,
                          expression: "formRules.book.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.book.name,
                        placeholder: _vm.$t(_vm.formRules.book.placeholder),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingListAssetBook,
                        allowClear: true
                      },
                      on: { change: _vm.handleChangeBook }
                    },
                    _vm._l(_vm.assetBookList, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.bookName } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.bookName) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.bookName) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.category.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.category.decorator,
                        expression: "formRules.category.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.category.name,
                      placeholder: _vm.$t(_vm.formRules.category.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: {
                    label: _vm.$t(_vm.formRules.depreciationAccount.label)
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.depreciationAccount.decorator,
                        expression: "formRules.depreciationAccount.decorator"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      name: _vm.formRules.depreciationAccount.name,
                      placeholder:
                        _vm.formRules.depreciationAccount.placeholder,
                      rows: 2
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: {
                    label: _vm.$t(
                      _vm.formRules.accumulatedDepreciationAccount.label
                    )
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value:
                          _vm.formRules.accumulatedDepreciationAccount
                            .decorator,
                        expression:
                          "formRules.accumulatedDepreciationAccount.decorator"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      name: _vm.formRules.accumulatedDepreciationAccount.name,
                      placeholder:
                        _vm.formRules.accumulatedDepreciationAccount
                          .placeholder,
                      rows: 2
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.description.label) }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.description.decorator,
                        expression: "formRules.description.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.description.name,
                      placeholder: _vm.formRules.description.placeholder,
                      rows: 4,
                      disabled: _vm.mode === "save" ? false : true
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.parentUnitCode.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.parentUnitCode.decorator,
                        expression: "formRules.parentUnitCode.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.parentUnitCode.name,
                      placeholder: _vm.$t(
                        _vm.formRules.parentUnitCode.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.unit.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.unit.decorator,
                        expression: "formRules.unit.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.unit.name,
                      placeholder: _vm.$t(_vm.formRules.unit.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.serialNumber.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.serialNumber.decorator,
                        expression: "formRules.serialNumber.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.serialNumber.name,
                      placeholder: _vm.$t(
                        _vm.formRules.serialNumber.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.unitCode.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.unitCode.decorator,
                        expression: "formRules.unitCode.decorator"
                      }
                    ],
                    staticClass: "w-100",
                    staticStyle: { "margin-bottom": "1rem" },
                    attrs: {
                      name: _vm.formRules.unitCode.name,
                      placeholder: _vm.$t(_vm.formRules.unitCode.placeholder),
                      disabled: ""
                    }
                  }),
                  _c("a-button", { on: { click: _vm.handleViewUnitCode } }, [
                    _vm._v("List Unit Code")
                  ])
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.location.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.location.decorator,
                        expression: "formRules.location.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.location.name,
                      placeholder: _vm.$t(_vm.formRules.location.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.status.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.status.decorator,
                        expression: "formRules.status.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.status.name,
                      placeholder: _vm.$t(_vm.formRules.status.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.type.label) }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.type.decorator,
                          expression: "formRules.type.decorator"
                        }
                      ],
                      attrs: {
                        disabled: _vm.mode === "save" ? false : true,
                        name: _vm.formRules.type.name,
                        placeholder: _vm.$t(_vm.formRules.type.placeholder),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingStatus,
                        allowClear: true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getDataAssetTag(value, "type")
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "dropdownRender",
                          fn: function(menu) {
                            return _c(
                              "div",
                              {},
                              [
                                _c("v-nodes", { attrs: { vnodes: menu } }),
                                _c("a-divider", {
                                  staticStyle: { margin: "4px 0" }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "8px",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      mousedown: function(e) {
                                        return e.preventDefault()
                                      },
                                      click: function($event) {
                                        return _vm.addNew("type")
                                      }
                                    }
                                  },
                                  [
                                    _c("a-icon", { attrs: { type: "plus" } }),
                                    _vm._v(" Add New ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        }
                      ])
                    },
                    _vm._l(_vm.dataType, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.capacity.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.capacity.decorator,
                        expression: "formRules.capacity.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.capacity.name,
                      placeholder: _vm.$t(_vm.formRules.capacity.placeholder),
                      disabled: _vm.mode === "save" ? false : true
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.salvageValue.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.salvageValue.decorator,
                        expression: "formRules.salvageValue.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.salvageValue.name,
                      placeholder: _vm.$t(
                        _vm.formRules.salvageValue.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: {
                    help:
                      "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX",
                    label: _vm.$t(_vm.formRules.attachment.label)
                  }
                },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        multiple: false,
                        method: "post",
                        "file-list": _vm.fileList,
                        customRequest: _vm.customRequest,
                        "before-upload": _vm.beforeUpload,
                        accept: ".pdf, .png, .jpg, .xlsx"
                      },
                      on: { change: _vm.handleChange }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.mode === "save" ? false : true
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, xs: 24, s: 24, md: 24, lg: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.invoiceNumber.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceNumber.decorator,
                        expression: "formRules.invoiceNumber.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.invoiceNumber.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceNumber.placeholder
                      ),
                      disabled: _vm.mode === "save" ? false : true
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.poNumber.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.poNumber.decorator,
                        expression: "formRules.poNumber.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.poNumber.name,
                      placeholder: _vm.$t(_vm.formRules.poNumber.placeholder),
                      disabled: _vm.mode === "save" ? false : true
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.supplierName.label) }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.supplierName.decorator,
                          expression: "formRules.supplierName.decorator"
                        }
                      ],
                      attrs: {
                        disabled: _vm.mode === "save" ? false : true,
                        name: _vm.formRules.supplierName.name,
                        placeholder: _vm.$t(
                          _vm.formRules.supplierName.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingData,
                        allowClear: true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getListContact(value, "supplier", "Dealer")
                        }
                      }
                    },
                    _vm._l(_vm.dataSupplierDealer, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s("" + data.fullName) + " ")
                              ]),
                              _vm._v(" " + _vm._s("" + data.fullName) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.customerName.decorator,
                        expression: "formRules.customerName.decorator"
                      }
                    ],
                    staticStyle: { width: "100%", "margin-bottom": "1rem" },
                    attrs: {
                      name: _vm.formRules.customerName.name,
                      placeholder: _vm.$t(
                        _vm.formRules.customerName.placeholder
                      ),
                      disabled: ""
                    }
                  }),
                  _c(
                    "a-button",
                    { on: { click: _vm.handleViewInternalContract } },
                    [_vm._v("List Customer")]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.customerLocation.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.customerLocation.decorator,
                        expression: "formRules.customerLocation.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.customerLocation.name,
                      placeholder: _vm.$t(
                        _vm.formRules.customerLocation.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.employeeName.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.employeeName.decorator,
                        expression: "formRules.employeeName.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.employeeName.name,
                      placeholder: _vm.$t(
                        _vm.formRules.employeeName.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.model.label) }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.model.decorator,
                          expression: "formRules.model.decorator"
                        }
                      ],
                      attrs: {
                        disabled: _vm.mode === "save" ? false : true,
                        name: _vm.formRules.model.name,
                        placeholder: _vm.$t(_vm.formRules.model.placeholder),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingStatus,
                        allowClear: true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getDataAssetTag(value, "model")
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "dropdownRender",
                          fn: function(menu) {
                            return _c(
                              "div",
                              {},
                              [
                                _c("v-nodes", { attrs: { vnodes: menu } }),
                                _c("a-divider", {
                                  staticStyle: { margin: "4px 0" }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "8px",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      mousedown: function(e) {
                                        return e.preventDefault()
                                      },
                                      click: function($event) {
                                        return _vm.addNew("model")
                                      }
                                    }
                                  },
                                  [
                                    _c("a-icon", { attrs: { type: "plus" } }),
                                    _vm._v(" Add New ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        }
                      ])
                    },
                    _vm._l(_vm.dataModel, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.assetCost.label) }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.assetCost.decorator,
                        expression: "formRules.assetCost.decorator"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      name: _vm.formRules.assetCost.name,
                      placeholder: _vm.$t(_vm.formRules.assetCost.placeholder),
                      parser: _vm.formatNumeric,
                      formatter: _vm.formatCurrency,
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.lifeMonths.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.lifeMonths.decorator,
                        expression: "formRules.lifeMonths.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.lifeMonths.name,
                      placeholder: _vm.$t(_vm.formRules.lifeMonths.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.acquisitionDate.label) }
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.acquisitionDate.decorator,
                        expression: "formRules.acquisitionDate.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.acquisitionDate.name,
                      placeholder: _vm.$t(
                        _vm.formRules.acquisitionDate.placeholder
                      ),
                      format: _vm.DEFAULT_DATE_FORMAT,
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.depreciate.label) }
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.depreciate.decorator,
                        expression: "formRules.depreciate.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.depreciate.name,
                      placeholder: _vm.$t(_vm.formRules.depreciate.placeholder),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { label: _vm.$t(_vm.formRules.leasing.label) }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      name: _vm.formRules.leasing.name,
                      disabled: "",
                      placeholder: _vm.$t(_vm.formRules.leasing.placeholder)
                    },
                    model: {
                      value: _vm.leasing,
                      callback: function($$v) {
                        _vm.leasing = $$v
                      },
                      expression: "leasing"
                    }
                  })
                ],
                1
              ),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t(_vm.formRules.leasingNumber.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.leasingNumber.decorator,
                            expression: "formRules.leasingNumber.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.leasingNumber.name,
                          placeholder: _vm.$t(
                            _vm.formRules.leasingNumber.placeholder
                          ),
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.leasingDate.label) }
                    },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.leasingDate.decorator,
                            expression: "formRules.leasingDate.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.leasingDate.name,
                          placeholder: _vm.$t(
                            _vm.formRules.leasingDate.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.formRules.tax.label }
                    },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.tax.decorator,
                            expression: "formRules.tax.decorator"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          max: 100,
                          name: _vm.formRules.tax.name,
                          placeholder: _vm.formRules.tax.placeholder,
                          disabled: _vm.disableTax
                            ? _vm.disableTax
                            : _vm.mode === "save"
                            ? false
                            : true,
                          parser: _vm.InputNumberOnly
                        },
                        on: { change: _vm.handleTaxAndChangeDp }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.formRules.downPayment.label }
                    },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.downPayment.decorator,
                            expression: "formRules.downPayment.decorator"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          max: 100,
                          name: _vm.formRules.downPayment.name,
                          placeholder: _vm.formRules.downPayment.placeholder,
                          disabled: _vm.disableDp
                            ? _vm.disableDp
                            : _vm.mode === "save"
                            ? false
                            : true,
                          parser: _vm.InputNumberOnly
                        },
                        on: { change: _vm.handleTaxAndChangeDp }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.formRules.leasingDPAmount.label }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.leasingDPAmount.decorator,
                            expression: "formRules.leasingDPAmount.decorator"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          name: _vm.formRules.leasingDPAmount.name,
                          placeholder:
                            _vm.formRules.leasingDPAmount.placeholder,
                          disabled: _vm.disableDpAmount
                            ? _vm.disableDpAmount
                            : _vm.mode === "save"
                            ? false
                            : true
                        },
                        on: {
                          change: _vm.handleChangeDpAmount,
                          blur: function($event) {
                            return _vm.onBlur($event, "leasingDPAmount")
                          },
                          focus: function($event) {
                            return _vm.onFocus($event, "leasingDPAmount")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.leasorName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.leasorName.decorator,
                              expression: "formRules.leasorName.decorator"
                            }
                          ],
                          attrs: {
                            disabled: "",
                            name: _vm.formRules.leasorName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.leasorName.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loadingData,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListContact(
                                value,
                                "supplier",
                                "Leasing"
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataSupplierLeasing, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.fullName } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.fullName) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.fullName) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.formRules.leasingPeriod.label }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.leasingPeriod.decorator,
                            expression: "formRules.leasingPeriod.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.leasingPeriod.name,
                          placeholder: _vm.formRules.leasingPeriod.placeholder,
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.leasing
                ? _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t(_vm.formRules.totalLeasingCost.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.totalLeasingCost.decorator,
                            expression: "formRules.totalLeasingCost.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.totalLeasingCost.name,
                          placeholder: _vm.$t(
                            _vm.formRules.totalLeasingCost.placeholder
                          ),
                          disabled: ""
                        },
                        on: {
                          blur: function($event) {
                            return _vm.onBlur($event, "totalLeasingCost")
                          },
                          focus: function($event) {
                            return _vm.onFocus($event, "totalLeasingCost")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visibleAdditional
                ? _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.visibleAdditional = false
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "minus-square" } }),
                      _vm._v(" Additional Form ")
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.visibleAdditional
                ? _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.visibleAdditional = true
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus-square" } }),
                      _vm._v(" Additional Form ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.additionalForm, function(data) {
                return _c(
                  "a-form-model-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visibleAdditional,
                        expression: "visibleAdditional"
                      }
                    ],
                    key: data.key,
                    staticStyle: { "margin-top": "1rem" },
                    attrs: {
                      label: data.propertyLabel,
                      "validate-status":
                        !_vm.modelAdditionalForm[data.propertyName] &&
                        data.required
                          ? "error"
                          : "",
                      help:
                        !_vm.modelAdditionalForm[data.propertyName] &&
                        data.required
                          ? _vm.$t(_vm.Messages.VALIDATION_REQUIRED_ERROR)
                          : "",
                      required: data.required
                    }
                  },
                  [
                    data.propertyName === "fuel"
                      ? _c(
                          "a-select",
                          {
                            attrs: {
                              disabled: _vm.mode === "save" ? false : true,
                              name: data.propertyName,
                              placeholder: data.propertyPlaceholder,
                              showSearch: true,
                              "option-filter-prop": "children",
                              "filter-option": _vm.filterOption,
                              loading: _vm.loadingStatus,
                              allowClear: true
                            },
                            on: {
                              search: function(value) {
                                return _vm.getDataAssetTag(value, "fuel")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dropdownRender",
                                  fn: function(menu) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("v-nodes", {
                                          attrs: { vnodes: menu }
                                        }),
                                        _c("a-divider", {
                                          staticStyle: { margin: "4px 0" }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              padding: "8px",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              mousedown: function(e) {
                                                return e.preventDefault()
                                              },
                                              click: function($event) {
                                                return _vm.addNew("fuel")
                                              }
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "plus" }
                                            }),
                                            _vm._v(" Add New ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.modelAdditionalForm[data.propertyName],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.modelAdditionalForm,
                                  data.propertyName,
                                  $$v
                                )
                              },
                              expression:
                                "modelAdditionalForm[data.propertyName]"
                            }
                          },
                          _vm._l(_vm.dataFuel, function(data, index) {
                            return _c(
                              "a-select-option",
                              { key: index, attrs: { value: data } },
                              [
                                _c(
                                  "a-tooltip",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(" " + _vm._s(data) + " ")
                                    ]),
                                    _vm._v(" " + _vm._s(data) + " ")
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c("a-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            name: data.propertyName,
                            placeholder: data.propertyPlaceholder,
                            disabled: _vm.mode === "save" ? false : true
                          },
                          model: {
                            value: _vm.modelAdditionalForm[data.propertyName],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.modelAdditionalForm,
                                data.propertyName,
                                $$v
                              )
                            },
                            expression: "modelAdditionalForm[data.propertyName]"
                          }
                        })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            {
              staticStyle: { display: "flex", "justify-content": "flex-end" },
              attrs: { span: 24 }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "0.5rem" },
                  on: { click: _vm.handleGenerateQr }
                },
                [_vm._v("View QR")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "0.5rem" },
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
              ),
              _vm.status !== "Retired" && _vm.$can("update", "asset")
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: {
                        click: function($event) {
                          return _vm.handleUpdate(_vm.mode)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.mode === "update" ? "Update" : "Save"))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            keyboard: false,
            title: "View QR Code",
            "ok-text": "Print QR Code",
            width: 720
          },
          on: { ok: _vm.handleOkQr },
          model: {
            value: _vm.visibleQr,
            callback: function($$v) {
              _vm.visibleQr = $$v
            },
            expression: "visibleQr"
          }
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                  attrs: { span: 24 }
                },
                [_c("h2", [_vm._v("Scan QR Code")])]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "-webkit-print-color-adjust": "exact" },
              attrs: { id: "printMe" }
            },
            [
              _vm.valueQr.length > 0
                ? _c(
                    "a-row",
                    { staticStyle: { "margin-top": "2rem" } },
                    _vm._l(_vm.valueQr, function(dataQR, index) {
                      return _c(
                        "a-col",
                        {
                          key: index,
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center"
                          },
                          attrs: {
                            span:
                              _vm.valueQr.length === 1
                                ? 24
                                : _vm.valueQr.length === 2
                                ? 12
                                : _vm.valueQr.length > 2
                                ? 8
                                : null
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "justify-content": "center",
                                "align-items": "center",
                                display: "flex",
                                "flex-direction": "column"
                              }
                            },
                            [
                              _c("QrCode", {
                                attrs: {
                                  value: dataQR,
                                  quality: 1,
                                  scale: 5,
                                  size: 189
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-family": "Arial, sans-serif",
                                    "font-size": "32px",
                                    "align-items": "center",
                                    "margin-bottom": "0"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dataAssetHistory.assetDetail
                                          .unitCode
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 720,
            title: "List " + _vm.titleModal,
            showmodal: _vm.visibleIC,
            idmodal: "modal2"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c("TableCustom", {
                attrs: {
                  dataSource:
                    _vm.titleModal === "Customer"
                      ? _vm.dataSourceCustomer
                      : _vm.dataSourceUnitCode,
                  columns:
                    _vm.titleModal === "Customer"
                      ? _vm.columnsTableCustomer
                      : _vm.columnsTableUnitCode,
                  scroll: { x: 650, y: 500 },
                  paginationcustom: false,
                  defaultPagination: true
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancelInternalContract }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }