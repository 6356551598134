
















































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import {
  AsetCustomerDTOs,
  AssetChilDTOs,
  CustomListCategory,
  DataListAssetBook,
  FormFieldsResponseFormAssetCategoryById,
  RequestUpdateMaster,
  ResponseGetDetailInquiry,
} from "@/models/interface/assets.interface";
import { debounce, debounceProcess } from "@/helpers/debounce";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import {
  ContactData,
  CustomAddressDataList,
  ResponseListContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { contactServices } from "@/services/contact.service";
import { DataWarehouseLocation } from "@/models/interface/logistic.interface";
import moment from "moment";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  numericOnly,
} from "@/validator/globalvalidator";
import { Messages } from "@/models/enums/messages.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { UploadFile } from "ant-design-vue/types/upload";
import { PropsModel } from "@/models/constant/interface/common.interface";
import printJs from "print-js";

export default Vue.extend({
  props: {
    dataAssetHistory: {
      type: Object as PropType<ResponseGetDetailInquiry>,
    },
    currentTab: String,
    id: String,
  },
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
  watch: {
    dataAssetHistory: {
      immediate: true,
      deep: true,
      handler() {
        this.assignDataAndSetFields();
      },
    },
  },
  data() {
    this.getListContact = debounceProcess(this.getListContact, 200);
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    this.getDataAssetTag = debounceProcess(this.getDataAssetTag, 200);
    return {
      Messages,
      DEFAULT_DATE_FORMAT,
      mode: "update" as "update" | "save",
      valueQr: [] as string[],
      visibleQr: false as boolean,
      visibleIC: false as boolean,
      visibleAdditional: false as boolean,
      fileList: [] as UploadFile[],
      InputNumberOnly: numericOnly,
      formatNumeric: numericOnly,
      formatCurrency: currencyFormat,
      urlForSend: "" as string,
      valueAddNew: "" as string,
      dataType: [] as string[],
      dataModel: [] as string[],
      dataFuel: [] as string[],
      dataSourceCustomer: [] as AsetCustomerDTOs[],
      dataSourceUnitCode: [] as AssetChilDTOs[],
      columnsTableCustomer: [
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
        },
        {
          title: this.$t("lbl_customer_location"),
          dataIndex: "customerLocation",
          key: "customerLocation",
        },
        {
          title: "Qty Rent",
          dataIndex: "qty",
          key: "qty",
        },
        {
          title: "IC Number",
          dataIndex: "internalContractNumber",
          key: "internalContractNumber",
        },
        {
          title: this.$root.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "isEnable" },
        },
      ],
      columnsTableUnitCode: [
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
        },
      ],
      dataListBook: [] as DataListAssetBook[],
      dataListCategory: [] as CustomListCategory[],
      dataListStatus: [] as ResponseListMaster[],
      dataSupplierDealer: [] as ContactData[],
      dataSupplierLeasing: [] as ContactData[],
      dataCustomer: [] as ContactData[],
      dataSupplier: [] as ContactData[],
      dataEmployee: [] as ContactData[],
      dataListLocation: [] as DataWarehouseLocation[],
      dataCustomerLocation: [] as CustomAddressDataList[],
      loadingButton: false as boolean,
      loading: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingListAssetCategory: false as boolean,
      loadingStatus: false as boolean,
      loadingData: false as boolean,
      loadingCustomerLocation: false as boolean,
      loadingLocation: false as boolean,
      loadingTag: false as boolean,
      disableDpAmount: false as boolean,
      disableDp: false as boolean,
      disableTax: false as boolean,
      leasing: true as boolean,
      status: "" as string,
      titleModal: "" as string,
      assetBookList: [] as {
        depreciate: boolean;
        bookType: string;
        currentCostAsset: number;
        bookName: string;
        acquisitionDate: string;
      }[],
      additionalForm: [] as FormFieldsResponseFormAssetCategoryById[],
      modelAdditionalForm: {} as any, // v-model additional form
      form: this.$form.createForm(this, { name: "openAssetDetails" }),
      formRules: {
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["book"],
        },
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["category"],
        },
        depreciationAccount: {
          label: "lbl_depreciation_account",
          name: "depreciationAccount",
          placeholder: "< auto generate dari category >",
          decorator: ["depreciationAccount"],
        },
        accumulatedDepreciationAccount: {
          label: "lbl_accumulated_depreciation_account",
          name: "accumulatedDepreciationAccount",
          placeholder: "< auto generate dari category >",
          decorator: ["accumulatedDepreciationAccount"],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: ["assetNumber"],
        },
        type: {
          label: "lbl_type",
          name: "type",
          placeholder: "lbl_type_placeholder",
          decorator: ["type"],
        },
        capacity: {
          label: "lbl_capacity",
          name: "capacity",
          placeholder: "lbl_capacity_placeholder",
          decorator: ["capacity"],
        },
        salvageValue: {
          label: "lbl_salvage_value",
          name: "salvageValue",
          placeholder: "lbl_salvage_value_placeholder",
          decorator: ["salvageValue"],
        },
        attachment: {
          label: "lbl_attachment_file",
          name: "attachment",
          placeholder: "lbl_attachment_file_placeholder",
          decorator: ["attachment"],
        },
        leasingNumber: {
          label: "lbl_leasing_number",
          name: "leasingNumber",
          placeholder: "lbl_leasing_number_placeholder",
          decorator: ["leasingNumber"],
        },
        leasingDate: {
          label: "lbl_leasing_date",
          name: "leasingDate",
          placeholder: "lbl_leasing_date_placeholder",
          decorator: ["leasingDate"],
        },
        downPayment: {
          label: "Down Payment (%)",
          name: "downPayment",
          placeholder: "Insert Down Payment (%)",
          decorator: ["downPayment"],
        },
        tax: {
          label: "Tax (%)",
          name: "tax",
          placeholder: "Insert your Tax",
          decorator: ["tax"],
        },
        leasingDPAmount: {
          label: "Down Payment (Amount)",
          name: "leasingDPAmount",
          placeholder: "Insert your Down Payment (Amount)",
          decorator: ["leasingDPAmount"],
        },
        leasorName: {
          label: "lbl_lessor_name",
          name: "leasorName",
          placeholder: "lbl_lessor_name_placeholder",
          decorator: ["leasorName"],
        },
        leasingPeriod: {
          label: "Leasing Period (Months)",
          name: "leasingPeriod",
          placeholder: "Leasing Period",
          decorator: ["leasingPeriod"],
        },
        totalLeasingCost: {
          label: "lbl_total_leasing_cost",
          name: "totalLeasingCost",
          placeholder: "lbl_total_leasing_cost_placeholder",
          decorator: ["totalLeasingCost"],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "Insert your Description",
          decorator: ["description"],
        },
        parentUnitCode: {
          label: "lbl_unit_code_parent",
          name: "parentUnitCode",
          placeholder: "lbl_unit_code_parent_placeholder",
          decorator: ["parentUnitCode"],
        },
        unit: {
          label: "lbl_qty",
          name: "unit",
          placeholder: "lbl_qty_placeholder",
          decorator: ["unit"],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: ["serialNumber"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["unitCode"],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["location"],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["status"],
        },
        invoiceNumber: {
          label: "lbl_invoice_number",
          name: "invoiceNumber",
          placeholder: "lbl_invoice_number_placeholder",
          decorator: ["invoiceNumber"],
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_po_number_placeholder",
          decorator: ["poNumber"],
        },
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["supplierName"],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: ["customerName"],
        },
        customerLocation: {
          label: "lbl_customer_location",
          name: "customerLocation",
          placeholder: "lbl_customer_location_placeholder",
          decorator: ["customerLocation"],
        },
        employeeName: {
          label: "lbl_employee_name",
          name: "employeeName",
          placeholder: "lbl_employee_name_placeholder",
          decorator: ["employeeName"],
        },
        model: {
          label: "lbl_model",
          name: "model",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["model"],
        },
        assetCost: {
          label: "lbl_asset_cost_dpp",
          name: "assetCost",
          placeholder: "lbl_asset_cost_dpp_placeholder",
          decorator: ["assetCost"],
        },
        lifeMonths: {
          label: "lbl_life_months",
          name: "lifeMonths",
          placeholder: "lbl_life_months_placeholder",
          decorator: ["lifeMonths"],
        },
        acquisitionDate: {
          label: "lbl_acquisition_date",
          name: "acquisitionDate",
          placeholder: "lbl_acquisition_date_placeholder",
          decorator: ["acquisitionDate"],
        },
        leasing: {
          label: "lbl_leasing",
          name: "leasing",
          placeholder: "lbl_leasing_placeholder",
          decorator: ["leasing"],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "lbl_depreciate_placeholder",
          decorator: [
            "depreciate",
            {
              valuePropName: "checked",
            },
          ],
        },
      },
    };
  },
  methods: {
    assignDataAndSetFields() {
      const {
        assetNo,
        // assetCategoryDesc,
        // depreAccount,
        // accumulatedDepreAccount,
        description,
        unit,
        serialNumber,
        unitCode,
        location,
        status,
        type,
        capacity,
        leasingNumber,
        leasingDate,
        // attachment,
        invoiceNumber,
        purchaseOrderNumber,
        supplier,
        customerName,
        customerLocation,
        employeeName,
        model,
        lifeMonth,
        leasing,
        // assetBookNameList,
        // bookName,
        assetCategory,
        salvageValue,
        leasingPercent,
        leasorName,
        leasingPeriod,
        totalLeasingCost,
        fileAttachment,
        leasingValue,
        leasingTax,
        parentUnitCode,
      } = this.dataAssetHistory.assetDetail;

      this.assetBookList = this.dataAssetHistory.assetBookRelations;
      this.dataSourceCustomer =
        this.dataAssetHistory.assetDetail.assetCustomerDTOs.map(dataMap => {
          return { ...dataMap, qty: currencyFormat(dataMap.qty) };
        });
      this.dataSourceUnitCode =
        this.dataAssetHistory.assetDetail.assetChilDTOs.map(
          (dataMap, index) => {
            return { ...dataMap, key: index };
          }
        );
      this.leasing = leasing;
      this.urlForSend = fileAttachment;
      if (fileAttachment) {
        const splited = fileAttachment.split("/");
        this.fileList = [
          ...this.fileList,
          {
            uid: "1",
            name: splited[splited.length - 1],
            status: "done",
            url: fileAttachment,
            size: 0,
            type: "",
          },
        ];
      }

      this.status = status;
      this.getFormAssetCategoryById(assetCategory.category.id);

      const [assetBook] = this.assetBookList;
      const currentBook = this.dataAssetHistory.assetBookRelations.find(
        e => e.bookType === (assetBook?.bookType ?? "")
      );
      const isDepre: boolean = currentBook?.depreciate ?? false;

      debounce(() => {
        this.form.setFieldsValue({
          book: assetBook?.bookName || "",
          assetCost: assetBook?.currentCostAsset,
          category: assetCategory.category.id,
          depreciationAccount: `${assetCategory.depreciationExpenseAccount.code} ${assetCategory.depreciationExpenseAccount.description}`,
          accumulatedDepreciationAccount: `${assetCategory.accumulatedDepreciationAccount.code} ${assetCategory.accumulatedDepreciationAccount.description}`,
          // assetNumber: this.dataAssetHistory.assetDetailDTO,
          assetNumber: assetNo,
          type: type,
          capacity: capacity,
          salvageValue: salvageValue,
          // attachment: attachment,
          leasingNumber: leasingNumber,
          leasingDate: leasingDate
            ? moment(leasingDate, DEFAULT_DATE_FORMAT)
            : undefined,
          downPayment: leasingPercent,
          leasorName: leasorName,
          leasingPeriod: leasingPeriod,
          totalLeasingCost: this.formatCurrency(totalLeasingCost),
          description,
          unit,
          serialNumber,
          unitCode,
          location,
          status,
          invoiceNumber,
          parentUnitCode,
          poNumber: purchaseOrderNumber,
          supplierName: supplier,
          customerName: customerName,
          customerLocation: customerLocation,
          employeeName: employeeName,
          model: model,
          // assetCost: assetC,
          lifeMonths: lifeMonth,
          leasing: leasing,
          leasingDPAmount: this.formatCurrency(leasingValue),
          tax: leasingTax,
          depreciate: isDepre,
        });
        const acquisitionDate = this.assetBookList.find(book => {
          return book.bookName === this.form.getFieldValue("book");
        })?.acquisitionDate;

        this.form.setFieldsValue({
          acquisitionDate,
        });
      }, 500);
    },
    getFormAssetCategoryById(idCategory) {
      assetsServices.listFormAssetCategoryById(idCategory).then(response => {
        this.additionalForm = response.formFields.map((data, index) => {
          return { ...data, key: index };
        });
        if (response.formFields.length < 1) {
          this.modelAdditionalForm = {};
        } else {
          const temp = {};
          response.formFields.forEach(dataForeach => {
            temp[dataForeach.propertyName] = dataForeach.defaultValue;
          });
          this.modelAdditionalForm = temp;
        }
        if (
          this.dataAssetHistory.assetDetail.additionalProperties &&
          Object.keys(this.dataAssetHistory.assetDetail.additionalProperties)
            .length > 0
        ) {
          this.modelAdditionalForm =
            this.dataAssetHistory.assetDetail.additionalProperties;
        }
      });
    },
    handleTaxAndChangeDp(value) {
      if (value) {
        this.form.setFieldsValue({
          leasingDPAmount: undefined,
        });
        this.disableDpAmount = true;
      } else {
        this.disableDpAmount = false;
      }
    },
    handleChangeDpAmount(value) {
      if (value.target.value) {
        this.form.setFieldsValue({
          downPayment: undefined,
          tax: undefined,
        });
        this.disableDp = true;
        this.disableTax = true;
      } else {
        this.disableDp = false;
        this.disableTax = false;
      }
    },
    onBlur(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: string };
        dataobj[form] = this.formatCurrency(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: number };
        dataobj[form] = changeCurrencytoNumeric(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    handleCancelInternalContract() {
      this.visibleIC = false;
    },
    handleViewInternalContract() {
      this.visibleIC = true;
      this.titleModal = "Customer";
    },
    handleViewUnitCode() {
      this.visibleIC = true;
      this.titleModal = "Unit Code";
    },
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= 5242880) {
        this.$notification.error({
          description: "File must smaller than 5MB!",
          message: "Error",
          duration: 30,
        });
      }
      return isLt5M;
    },
    handleOkQr() {
      this.visibleQr = false;
      const params: RequestQueryParamsModel = {
        unitCodes: this.dataAssetHistory.assetDetail.unitCode,
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        }
      });
    },
    handleGenerateQr() {
      if (this.id) {
        this.visibleQr = true;
        this.valueQr = [this.dataAssetHistory.assetDetail.unitCode];
      }
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (info.file.status === "done") {
        this.pathFile = info.file.response.url;
        this.$notification.success({
          description: `${info.file.name} file uploaded successfully`,
          message: "Success",
          duration: 30,
        });
      } else if (info.file.status === "error") {
        this.$notification.error({
          description: `${info.file.name} file upload failed.`,
          message: "Error",
          duration: 30,
        });
      }
    },
    customRequest(options) {
      const data = new FormData();
      data.append("file", options.file);
      assetsServices
        .createUploadFile(data, "asset")
        .then(response => {
          options.onSuccess(options);
          this.urlForSend = response.url;
        })
        .catch(err => {
          options.onError(err, options);
        });
    },
    addNew(type): void {
      let valuesTag = [] as string[];
      valuesTag.push(this.valueAddNew);
      if (this.valueAddNew) {
        let payload = {
          values: valuesTag,
        };
        switch (type) {
          case "type":
            payload["tagId"] = "type";
            break;
          case "model":
            payload["tagId"] = "model";
            break;
          case "fuel":
            payload["tagId"] = "fuel";
            break;
          default:
            break;
        }
        assetsServices.addAssetTag(payload).then((data: any) => {
          switch (type) {
            case "type":
              data.forEach(element => {
                this.dataType.push(element);
              });
              break;
            case "model":
              data.forEach(element => {
                this.dataModel.push(element);
              });
              break;
            case "fuel":
              data.forEach(element => {
                this.dataFuel.push(element);
              });
              break;
            default:
              break;
          }
        });
      } else {
        switch (type) {
          case "type":
            this.$notification.error({
              message: "Error",
              description: "Please Add Type Name First",
            });
            break;
          case "model":
            this.$notification.error({
              message: "Error",
              description: "Please Add Model Name First",
            });
            break;
          case "fuel":
            this.$notification.error({
              message: "Error",
              description: "Please Add Fuel Name First",
            });
            break;
          default:
            break;
        }
      }
    },
    getDataAssetTag(valueSearch, id: "type" | "model" | "fuel") {
      this.valueAddNew = valueSearch;
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      // id 1 = type
      // id 2 = model
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loadingTag = true;
      assetsServices
        .getAssetTag(params, id)
        .then(data => {
          switch (id) {
            case "type":
              this.dataType = data.data;
              break;
            case "model":
              this.dataModel = data.data;
              break;
            case "fuel":
              this.dataFuel = data.data;
              break;
            default:
              break;
          }
        })
        .finally(() => (this.loadingTag = false));
    },
    handleUpdate(mode) {
      if (mode === "update") {
        this.mode = "save";
      } else {
        this.form.validateFields((err, res) => {
          if (err) return;
          const payload = {
            capacity: res.capacity,
            description: res.description,
            fileAttachment: this.urlForSend,
            invoiceNumber: res.invoiceNumber,
            leasingNumber: res.leasingNumber,
            leasingDate: res.leasingDate,
            model: res.model,
            purchaseOrderNumber: res.poNumber,
            supplierId: res.supplierName,
            type: res.type,
            leasingTax: res.tax,
            leasingPercent: res.downPayment,
            leasingValue: changeCurrencytoNumeric(res.leasingDPAmount),
            leasorName: res.leasorName,
            leasingPeriod: res.leasingPeriod,
            totalLeasingCost: changeCurrencytoNumeric(res.totalLeasingCost),
            additionalProperties: this.modelAdditionalForm,
          } as RequestUpdateMaster;
          this.loadingButton = true;
          assetsServices
            .updateMasterAsset(payload, this.id)
            .then(() => {
              this.$emit("getListHistoryAsset");
              this.mode = "update";
              this.$notification.success({
                description: Messages.UPDATE_SUCCESS,
                message: "Success",
                duration: 30,
              });
            })
            .catch(() =>
              this.$notification.error({
                description: Messages.UPDATE_FAIL,
                message: "Error",
                duration: 30,
              })
            )
            .finally(() => (this.loadingButton = false));
        });
      }
    },
    handleChangeBook(value) {
      this.assetBookList.forEach(dataForeach => {
        if (dataForeach.bookName === value) {
          this.form.setFieldsValue({
            assetCost: dataForeach.currentCostAsset,
            acquisitionDate: dataForeach.acquisitionDate,
            depreciate: dataForeach.depreciate,
          });
        }
      });
    },
    handleChangeCustomer(idContact) {
      this.idContact = idContact;
      this.getDetailContact();
    },
    getDetailContact() {
      this.loadingCustomerLocation = true;
      contactServices
        .getContactData(this.idContact)
        .then(data => (this.dataCustomerLocation = data.addressDataList))
        .finally(() => (this.loadingCustomerLocation = false));
    },
    getListContact(valueSearch, supplierOrCustomerOrEmployee, supplierType?) {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (
        valueSearch &&
        supplierType &&
        supplierOrCustomerOrEmployee === "supplier"
      ) {
        params.search = `supplierData.supplierType~${supplierType}_AND_active~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_OR_phoneNumber~*${valueSearch}*_OR_email~*${valueSearch}*_OR_${supplierOrCustomerOrEmployee}Number~*${valueSearch}*_OR_secureId~*${valueSearch}*`;
      } else if (valueSearch) {
        params.search = `${supplierOrCustomerOrEmployee}~true_AND_active~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_OR_phoneNumber~*${valueSearch}*_OR_email~*${valueSearch}*_OR_${supplierOrCustomerOrEmployee}Number~*${valueSearch}*_OR_secureId~*${valueSearch}*`;
      }
      this.loadingData = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          switch (supplierOrCustomerOrEmployee) {
            case "supplier":
              if (supplierType === "Dealer" && valueSearch) {
                this.dataSupplierDealer = res.data;
              } else if (supplierType === "Leasing" && valueSearch) {
                this.dataSupplierLeasing = res.data;
              }
              break;
            case "customer":
              this.dataCustomer = res.data;
              break;
            case "employee":
              this.dataEmployee = res.data;
              break;
            default:
              break;
          }
        })
        .finally(() => (this.loadingData = false));
    },
    getListStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_ADDITION_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    getListLocation(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*`;
      this.loadingLocation = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(data => {
          this.dataListLocation = data.data;
        })
        .finally(() => (this.loadingLocation = false));
    },
    handleChangeCategory(valueId) {
      this.dataListCategory.forEach(data => {
        if (data.id === valueId) {
          this.form.setFieldsValue({
            depreciationAccount: data.depreciationAccount,
            accumulatedDepreciationAccount:
              data.accumulatedDepreciationAccountId,
          });
        }
      });
    },
    getListAssetCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `categoryId~*${valueSearch}*_OR_description~*${valueSearch}*_OR_lifeMonths~${valueSearch}`;
      this.loadingListAssetCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              accumulatedDepreciationAccountId: `${
                data.accumulatedDepreciationAccount.code
                  ? data.accumulatedDepreciationAccount.code
                  : ""
              }-${data.accumulatedDepreciationAccount.description}`,
              depreciationAccount: `${
                data.depreciationExpenseAccount.code
                  ? data.depreciationExpenseAccount.code
                  : ""
              }-${data.depreciationExpenseAccount.description}`,
              first: "",
              second: "",
            } as CustomListCategory;
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
        })
        .finally(() => (this.loadingListAssetCategory = false));
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_type~*${valueSearch}*_OR_method~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    handleCancel() {
      this.$router.push("/inquiry/find");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetCategories("");
    this.getListAssetBook("");
    this.getListLocation("");
    this.getListStatus("");
  },
  mounted() {
    let paramsSupplierDealer = {
      limit: 10,
      page: 0,
      // search: `supplier~true_AND_active~true`
      search: `supplierData.supplierType~Dealer_AND_active~true`,
    } as RequestQueryParamsModel;
    let paramsCustomer = {
      limit: 10,
      page: 0,
      search: `customer~true_AND_active~true`,
    } as RequestQueryParamsModel;
    let paramsEmployee = {
      limit: 10,
      page: 0,
      search: `employee~true_AND_active~true`,
    } as RequestQueryParamsModel;
    let paramsSupplierLeasing = {
      limit: 10,
      page: 0,
      // search: `supplier~true_AND_active~true`
      search: `supplierData.supplierType~Leasing_AND_active~true`,
    } as RequestQueryParamsModel;
    let paramsTag = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    this.loadingTag = true;
    this.loadingData = true;
    const promise1 = contactServices.listContactData(paramsSupplierDealer);
    const promise2 = contactServices.listContactData(paramsCustomer);
    const promise3 = contactServices.listContactData(paramsEmployee);
    const promise4 = contactServices.listContactData(paramsSupplierLeasing);
    const promiseType = assetsServices.getAssetTag(paramsTag, "type");
    const promiseModel = assetsServices.getAssetTag(paramsTag, "model");
    const promiseFuel = assetsServices.getAssetTag(paramsTag, "fuel");
    Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
      promiseType,
      promiseModel,
      promiseFuel,
    ])
      .then((data: PropsModel[]) => {
        data.forEach((dataMap, index) => {
          switch (index) {
            case 0:
              this.dataSupplierDealer = dataMap.data;
              break;
            case 1:
              this.dataCustomer = dataMap.data;
              break;
            case 2:
              this.dataEmployee = dataMap.data;
              break;
            case 3:
              this.dataSupplierLeasing = dataMap.data;
              break;
            case 4:
              this.dataType = dataMap.data;
              break;
            case 5:
              this.dataModel = dataMap.data;
              break;
            case 6:
              this.dataFuel = dataMap.data;
              break;
            default:
              break;
          }
        });
      })
      .finally(() => {
        this.loadingData = false;
        this.loadingTag = false;
      });
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
